@import "node_modules/bulma/bulma";

main {
  max-width: 35rem;
  margin: 2rem auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

ol {
  list-style: none;
}

.button-group {
  margin: 1rem 0;
  overflow: auto;

  button {
    float: right;
  }

  button:first-child {
    margin-left: 0.5rem;
  }
}

.hour {
  padding: 1rem;
}

.forecast {
  padding-bottom: 1rem;
}
